@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

html {
  scroll-behavior: smooth;
  --defaultthemecolor: #FE0116;
  --defaultwhitecolor: #ffffff;
  --defaultbluecolor: #093EAB;
  --defaultFamily: 'Raleway', sans-serif;
}

.bg {
  background: url("../images//banner.png") no-repeat center/cover;
  width: 100%;
  height: 100vh;
}

.Logo {
  height: 70px;
  margin-bottom: 30px;
}

.Logo img {
  height: 100%;
  /* width: 100%; */
}

.account_form {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 1em 5rem;
}

.button_Commomn button {
  color: white;
  border: none;
  background: var(--defaultthemecolor);
  border-radius: 8px;

  padding: 10px;
  font-size: 0.9rem;
  font-family: var(--defaultRegularFamily);
  font-weight: 600;
}

.button_success {
  color: white;
  border: none;
  background: var(--defaultbluecolor);
  border-radius: 8px;
  padding: 10px;
  font-size: 0.8rem;
  font-family: var(--defaultRegularFamily);
  font-weight: 400;
}

.button_white {
  color: black;
  border: none;
  background: var(--defaultwhitecolor);
  border-radius: 8px;
  padding: 10px;
  font-size: 0.8rem;
  font-family: var(--defaultRegularFamily);
  font-weight: 400;
}

.button_cancel {
  color: white !important;
  border: none;
  background: var(--defaultthemecolor);
  border-radius: 8px;
  padding: 10px;
  font-size: 0.8rem;
  font-family: var(--defaultRegularFamily);
  font-weight: 400;
}

.btn-danger {
  color: white;
  border: none;
  background: var(--defaultthemecolor);
  border-radius: 8px;
  padding: 10px;
  font-size: 0.8rem;
  font-family: var(--defaultRegularFamily);
  font-weight: 400;
}

.btn-primary {
  color: white;
  border: none;
  background: var(--defaultbluecolor);
  border-radius: 8px;
  padding: 10px;
  font-size: 0.8rem;
  font-family: var(--defaultRegularFamily);
  font-weight: 400;
}

.button_Commomn {
  display: flex;
  justify-content: center;
}

.form_align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title_tag {
  font-family: var(--defaultFamily);
  font-size: 1.2rem;
  font-weight: 700;
}

.titleContent {
  font-family: var(--defaultRegularFamily);
  font-weight: 400;
  font-size: 0.8rem;
  text-align: center;
}

.inputs_placeholder ::placeholder {
  font-size: 0.8rem;
  opacity: 1;
  color: #828282;
}

.inputs_placeholder input {
  background-color: #F8F8F8;
  border: none;
  padding-left: 40px;
}

.inputs_placeholder1 ::placeholder {
  font-size: 0.8rem;
  opacity: 1;
  color: #828282;
}

.inputs_placeholder1 input {
  background-color: #F8F8F8;
  border: none;
  /* padding-left: 30px; */
}

.toggle_parent {
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
}

.toggle_child {
  position: absolute;
  color: #c7c3c3;
  font-weight: bold;
  font-size: 0.9rem;
  left: 10px;
  font-size: 18px;
  /* top: 9px; */

}

.eye_child {
  position: absolute;
  color: #c7c3c3;
  font-weight: bold;
  font-size: 0.9rem;
  right: 10px;
  font-size: 18px;
}

.LinkRouter {
  /* color: var(--defaultthemecolor); */
  text-decoration: none;
  color: inherit;


}

.LinkRouter:hover {
  color: var(--defaultthemecolor)
}

.titleContent.color {
  color: #828282;
}

.social_img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.social_img {
  height: 28px;
}

.bg_social {
  background-color: #F1F1F1;
  width: 3.5rem;
  height: 48px;
  padding: 8px;
  border-radius: 8px;
  margin: 5px;
  cursor: pointer;

}

.social {
  display: flex;
  justify-content: center;
}

.ant-layout {
  height: 100vh;
}

/* .ant-layout-sider{
  background-color: white !important;
} */
.headerbar {
  background-color: white !important;
  box-shadow: 0px 4px 4px #0000000A;
}

.sidebar {
  background-color: white !important;
  border: none !important;
}

.ant-layout-sider {
  flex: none !important;
  max-width: 274px !important;
  min-width: 274px !important;
  width: 260px !important;

}

.setting_img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.setting_img {
  height: 20px;
}

.header_profile {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

.avtar img {
  /* width: 100%; */
  height: 100%;
  border-radius: 50%;
}

.avtar {
  height: 40px;
  cursor: pointer;
}

.wrapp {
  display: flex;
}

.ant-menu-title-content {
  font-family: var(--defaultFamily);
  color: #8B8B8B;
  font-weight: 400;
}

.active {
  color: var(--defaultthemecolor) !important;
  font-family: var(--defaultFamily);
  font-weight: bold
}

.unactive {
  color: black;
  font-family: var(--defaultFamily);
  font-weight: bold
}

.card_home {
  display: flex;
  align-items: center;
}

.card_main {
  background-color: #093EAB;
  color: white;
  padding: 10px;
  border-radius: 8px;
}

.card_main_outline {
  color: #093EAB;
  padding: 10px;
  border-radius: 8px;
  background: white;
  border: 1px solid gray;
}


.card_Img img {
  height: 100%;
  /* width: 100%; */
  object-fit: contain;
}

.card_Img {
  height: 40px;
}

.card_home span {
  font-size: 1.0rem;
  font-family: var(--defaultFamily);
  font-weight: 600;

}

.counts {
  font-family: var(--defaultFamily);
  font-weight: 700;

  /* margin-left: 30px; */

}


.anticon.anticon-down {
  display: none !important;
}

/* .ant-avatar-circle {
  height: 26px !important;
} */
.ant-menu-item {
  color: grey;
}

.ant-menu-item:hover {
  color: grey;
}

.ant-menu-submenu-title {
  color: grey !important
}

.toogle_hide {
  visibility: hidden;
}

.search_bar {
  height: 40px;
  border-radius: 50px;
  background: #f0f0f0
}

.search_bar input {
  background: #f0f0f0
}


section {
  background-color: #F6F6F6 !important;

}

.ant-menu-inline {
  border-inline-end: 0px solid rgba(5, 5, 5, 0.06) !important;
}

/* side bar  */
.ant-menu-item-selected {
  background-color: #FFEFF0 !important;
  color: #FE0116 !important;

}

.ant-menu-item-selected span {
  color: #FE0116 !important;
  font-weight: 600;

}

.ant-menu-item:hover {
  color: white !important;
}

.ant-table table th {
  font-size: 13px;
}

.ant-table {
  box-shadow: 0px 0px 4px 5px #f0f0f0;
}

.sideContent {
  margin: 22px 16px;
  padding: 24;
  min-height: 280;
}

.breadcrumb {
  margin: 16px 12px;
}

#inner_form input:focus,
input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  /* background:#f0f0f0; */
  border-bottom: 0.1rem solid var(--defaultbluecolor) !important;
  -webkit-box-shadow: 0 1px 0 0 var(--defaultbluecolor);
  box-shadow: 0 1px 0 0 var(--defaultbluecolor);
}


#inner_form .form-control {
  background: transparent;
  border-radius: 0px;
  border: none !important;
  border-bottom: 0.1rem solid grey !important;
  font-family: var(--defaultFamily);
  font-size: 14px;
  padding: 10px 2px 5px 2px;
}

label {
  /* font-weight: bold; */
  color: gray;
  font-size: 13px;
  margin-bottom: 8px
}

.form-check-input {
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.3em;
  margin-right: 0.5em;
  border: 2px solid #ccc;
  border-radius: 0.25em;
}

.form-check-input:checked {
  background-color: var(--defaultbluecolor);
  border-color: var(--defaultbluecolor);
}

.form-switch .form-check-input {
  width: 3em !important
}

.form-check-input {
  height: 1.75em !important;
}

.form-check-label {
  margin-left: 0.5em;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

/* This css is for normalizing styles. You can skip this. */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}



.new {
  padding: 50px;
}

.form-group-checkbox {
  display: block;
  /* margin-bottom: 15px; */
}

.form-group-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group-checkbox label {
  position: relative;
  cursor: pointer;
}

.form-group-checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid var(--defaultbluecolor);
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%), inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 9px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 0px;
}

.form-group-checkbox input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 8px;
  width: 6px;
  height: 11px;
  border: solid var(--defaultthemecolor);
  ;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

#privilege_heading {
  font-weight: bolder;
  color: var(--defaultbluecolor)
}

.privilege_options span {
  font-weight: bolder;
  color: black;
}

.select-dropdown,
.select-dropdown * {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

.select-dropdown {
  position: relative;
  background-color: transparent;
  border-radius: 4px;
}

.select-dropdown select {
  color: black;
  font-weight: normal;
  max-width: 100%;
  padding: 10px 25px;
  border: none;
  border-radius: 10px;
  /* background-color: #FFCECE; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;

}

.select-dropdown:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 12px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #0c0b0b;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.text_blue_bold {
  font-weight: 600;
  color: var(--defaultbluecolor)
}

.ant-pagination-item-active {
  background-color: var(--defaultthemecolor) !important;
  border-color: var(--defaultthemecolor) !important;
  color: white !important;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spinner 0.8s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* ANSH */
.driver_input_modal {
  height: 3rem;
  border: 2px solid #093EAB;
}

.form-control {
  border: none;
  border-bottom: 2px solid #093EAB !important;
  border-radius: 0rem;
}

.claim_status {
  text-transform: capitalize;
  cursor: pointer;
  padding: 11px 20px;
  border-radius: 0.5rem;
  /* font-weight:600 */
}

li {
  color: #093EAB !important
}

li span {
  color: #434242 !important;
  font-weight: bold !important
}

.list_content {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* text-align: center; */
  padding: 10px 5px;
}

.list_content_span {
  color: #093EAB;
  font-size: large;
  margin-left: 12px;
}

/* .compony_report_select{
  
  display: flex;
  justify-content: center;
}

.report_select_input .ant-select-multiple:not(.ant-select-customize-input) .ant-select-selector {
  height: 55px !important;
  padding: 11px;
  background-color: #eef3fe;
} */
.compony_report_select .ant-select-selector {
  /* height: 55px !important; */
  padding: 8px;
  background-color: #eef3fe !important;
}

span.ant-select-selection-search {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #FE0116;
  background-color: #FE0116;
}

.driver_img_name {
  display: flex;
  gap: 10px;
}

.driver_info {
  /* height: 55px; */
  background-color: #bacaec;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.driver_info>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report_box_heading h6 {
  font-weight: bolder;
}

.report_total_count {
  text-align: right !important;
}

.upload_document {
  height: 50px;
  color: var(--defaultthemecolor);
  padding: 12px;
  border-radius: 10px;
  border: 2px solid var(--defaultthemecolor);
}

/* .ant-upload-list-item {
  display: none!important;
}  */
.ant-upload-list-item-actions {
  display: none !important;
}

/********************TAb in detail page******************/
.detail_tabs {
  color: var(--defaultWhite) !important;
  background: var(--defaultLightBlack) !important;
  /* padding: 25px!important  */
}

.ant-tabs-nav-wrap {
  background: var(--defaultMediumBlack) !important;
  border: 1px solid var(--defaultLightBlack) !important;
}

.ant-tabs-tab {
  padding: 15px 20px !important;
  color: var(--defaultbluecolor) !important;
}

.ant-tabs-tab-active {
  background: var(--defaultbluecolor) !important;
  color: var(--defaultwhitecolor) !important;
  border-bottom-color: red !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--defaultWhite) !important;
}

.tab_detail_box {
  /* cursor: pointer; */
  border: 1px solid #86888c4d !important;
  padding: 6px;
  border-radius: 5px;
  background-color: #fafafa;
}

.tab_detail_box .list_content {
  font-size: 14px;
}

.tab_detail_box .list_content_span {
  font-weight: bold;
}

.ant-tabs-content-holder {
  min-height: 400px;
  border: 1px solid #86888c4d !important;
}

.small_input {
  height: 40px !important;
  width: 105px !important;
  padding-left: 5px;
  /* text-align: center!important; */
  border: none !important;
  outline: 1px solid var(--defaultbluecolor)
}

.calculation_table>tbody {
  vertical-align: middle;
}

.marketing__card__container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.marketing__card {
  position: relative;
  width: max-content;
  min-height: 220px;
  min-width: 220px;
  border-radius: 10px;
  padding: 0.2rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.marketing__card>img {
  height: 220px;
  border-radius: 10px;
  width: 250px;
}

.marketing__card__delete__edit__btn {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.delete__btn {
  display: flex;
  color: #fff;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  background-color: #093EAB;
}

.delete__btn:hover {
  color: #093EAB;
  background-color: #fff;
  outline: 2px solid #093EAB !important;
}

.edit__btn {
  display: flex;
  color: #fff;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  background-color: #FE0116;
}

.edit__btn:hover {
  color: #FE0116;
  background-color: #fff;
  outline: 2px solid #FE0116 !important;
}

.marketing {
  height: 10rem;
  outline: 2px solid #093EAB;
  display: flex;
  justify-content: center;
}

.profile_input {
  height: 2.5rem;
  width: 100%;
}

.driverView {
  color: var(--defaultthemecolor);
  cursor: pointer;
  font-size: 1.5rem;
}

.ProfileImg img {
  height: 25px !important;
  width: 25px !important;
  border-radius: 100%;
}

.linksrepots span{
  margin: 3px 8px;
}
.linksrepots{
  /* justify-content: space-between; */
  display: flex;
  flex-wrap: wrap;
}
.tableMargin h5{ 
margin-top: 5px;
}
/* .DesTable {
    display: flex;
    justify-content: space-around;

} */

.ant-float-btn-icon{
color: red !important;
}
